import { render, staticRenderFns } from "./LandingFooter.vue?vue&type=template&id=694f15d9&"
import script from "./LandingFooter.vue?vue&type=script&setup=true&lang=ts&"
export * from "./LandingFooter.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./LandingFooter.vue?vue&type=style&index=0&id=694f15d9&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingLogo: require('/app/components/landing/LandingLogo.vue').default})
